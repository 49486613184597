<div class="logo-container">
  <a href="https://www.facebook.com/gracevillepc" target="_blank"
    ><ion-icon name="logo-facebook" class="facebook"></ion-icon
  ></a>
  <a href="https://twitter.com/gracevillepc" target="_blank"
    ><ion-icon name="logo-twitter" class="twitter"></ion-icon
  ></a>
  <a
    href="https://www.instagram.com/gracevillepresbyterianchurch/"
    target="_blank"
    ><ion-icon name="logo-instagram" class="instagram"></ion-icon
  ></a>
  <a
    href="https://www.youtube.com/channel/UCNPcTAoPcefs3sVpOdjhFbw"
    target="_blank"
    ><ion-icon name="logo-youtube" class="youtube"></ion-icon
  ></a>
</div>
