<app-nav [songs]="songs"></app-nav>

<div>
  <app-banner></app-banner>

  <div class="container pb-5">
    <ng-container *ngFor="let song of songs; let i = index">
      <app-song [song]="song"></app-song>
      <hr *ngIf="i !== songs.length - 1" />
    </ng-container>
  </div>
  <app-footer></app-footer>
</div>
