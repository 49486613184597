<nav>
  <app-toggle [(isOpen)]="isOpen"></app-toggle>
  <div class="nav-menu" [ngClass]="{ open: isOpen }">
    <ul>
      <li (click)="onSongSelect('Top')">Scroll to top</li>
      <li *ngFor="let song of songs" (click)="onSongSelect(song.title)">
        {{ song.title }}
      </li>
      <li (click)="onSongSelect('About Us')">About Us</li>
    </ul>
  </div>
</nav>
