<footer id="About Us">
  <div class="container py-5">
    <div class="card mb-3">
      <div class="card-body">
        <h5 class="card-title">Thank you for joining us</h5>
        <hr />
        <p class="card-text">
          We hope to see you again at one of our Christmas services. You can
          find us at 12 Bank Rd, Graceville.
        </p>
        <p class="card-text"><b>Christmas Eve</b> service at 9:30AM</p>
        <p class="card-text"><b>Christmas Day</b> service at 8:30AM</p>
        <p class="card-text"><b>New Year's Eve Service</b> service at 9:30AM</p>
        <app-icon-panel></app-icon-panel>
      </div>
    </div>
    <div class="gpc-card mb-3 cta">
      <div class="overlay"></div>
      <div class="card-body">
        <h1>Please keep in touch</h1>
        <a
          target="_blank"
          href="https://gracevillepresbyterian.org.au/"
          class="btn btn-find-us-online"
        >
          <ion-icon name="open-outline"></ion-icon>
          <span>Find us online</span>
        </a>
        <a
          target="_blank"
          href="https://gracevillepc.elvanto.com.au/form/4b531723-0448-45f5-b7f6-a07d9fa6e681"
          class="btn"
        >
          <ion-icon name="open-outline"></ion-icon>
          <span>Connect form</span>
        </a>
      </div>
    </div>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3538.3091383606375!2d152.9734996157681!3d-27.5218534255137!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b915036f06af6cb%3A0x3fe2dfe74716e623!2sGraceville%20Presbyterian%20Church!5e0!3m2!1sen!2sau!4v1606947548318!5m2!1sen!2sau"
      frameborder="0"
      style="border: 0"
      allowfullscreen=""
      aria-hidden="false"
      tabindex="0"
    ></iframe>
  </div>
</footer>
